export default function () {
    return {
        get visibilityChangeEvent() {
            if (typeof document.hidden !== 'undefined') {
                return 'visibilitychange';
            }
            if (typeof document.msHidden !== 'undefined') {
                return 'msvisibilitychange';
            }
            if (typeof document.webkitHidden !== 'undefined') {
                return 'webkitvisibilitychange';
            }
            return '';
        },
        dirtyProperties: [],
        get isDirty() {
            return this.dirtyProperties.length > 0;
        },
        searchForDirtyProperties() {
            this.dirtyProperties = [];

            // This doesn't exist anymore in Livewire 3
            // this.$wire.__instance.walk((el) => {
            //     if (el.getAttribute('data-dirty') === 'true') {
            //         this.dirtyProperties.push(el.getAttribute('name'));
            //     }
            // });
            document.querySelectorAll('[data-dirty="true"]').forEach(el => {
                this.dirtyProperties.push(el.getAttribute('name'));
            });
        },
        save() {
            this.$wire.autoSave('undefined', '');
        },
        init() {
            if (document.addEventListener) {
                document.addEventListener(this.visibilityChangeEvent, () => this.save());
            }
            if (window.addEventListener) {
                window.addEventListener('beforeunload', (event) => {
                    this.searchForDirtyProperties();
                    if (this.isDirty) {
                        this.save();
                        event.preventDefault();
                        return event.returnValue = 'Are you sure?';
                    }
                });
            }
        },
    }
}
