/**
 * Marks a table row that will be removed
 *
 * @param element
 * @param color
 */
window.markRemovedRowMain = function (element, color) {
    let tableRow = element.closest('tr');

    tableRow.style.backgroundColor = color;
    tableRow.classList.add('fadeOut');
    tableRow.style.opacity = 0;

    tableRow = null;
}


/**
 * Marks a list item that will be removed
 *
 * @param element
 * @param color
 *
 * @todo Make one function out of this
 */
window.markRemovedListItemMain = function (element, color) {
    let listElement = element.closest('li');

    listElement.style.backgroundColor = color;
    listElement.classList.add('fadeOut');
    listElement.style.opacity = 0;

    listElement = null;
}
