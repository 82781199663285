/**
 * Load additional JavaScript files
 */
// Where does this come from???
import {destroy} from 'filepond';

import './bootstrap';
import './helpers';
import './alpine-components';


/**
 * Other JavaScript modules
 */
// Upload
import * as FilePond from 'filepond';
window.FilePond = FilePond;

// Tooltips
import tippy from 'tippy.js';
window.tippy = tippy;
import { hideAll } from 'tippy.js';
window.hideall = hideAll;

// Date picker
import flatpickr from 'flatpickr';
//import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
// We need a slightly modified version of the confirmDatePlugin:
import confirmDatePlugin from './flatpickrConfirmDatePluginModified.js';
window.confirmDatePlugin = confirmDatePlugin;
import { German } from "flatpickr/dist/l10n/de.js";
flatpickr.localize(German);

// Custom observer for checking if a modal has been opened
import { observeParentElementRender } from "./parent-element-observer.js";
window.observeParentElementRender = observeParentElementRender;


/**
 * (Livewire 3 +) Alpine + Plugins + Extensions
 */
//import Alpine from 'alpinejs';
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

// These plugins come bundled with Livewire 3
// import collapse from '@alpinejs/collapse';
// import persist from '@alpinejs/persist';
// import focus from '@alpinejs/focus';

import Clipboard from "@ryangjchandler/alpine-clipboard";

// These plugins come bundled with Livewire 3
//Alpine.plugin(collapse);
//Alpine.plugin(persist);
//Alpine.plugin(focus);

Alpine.plugin(Clipboard);

window.Alpine   = Alpine;
window.Livewire = Livewire;

// Custom Alpine extensions have to be placed here
document.addEventListener('alpine:init', () => {
    // Magic: $tooltip
    Alpine.magic('tooltip', el => message => {
        let instance = tippy(el, {
            content: message,
            trigger: 'manual',
        });

        instance.show();

        setTimeout(() => {
            instance.hide();

            setTimeout(() => instance.destroy(), 150);
        }, 2000);
    });

    // Directive: x-tooltip
    Alpine.directive('tooltip', (el, { expression }) => {
        if (typeof el._tippy !== 'undefined' && el._tippy !== null) {
            el._tippy.setContent(expression)
        } else {
            tippy(el, {
                content : expression,
                //maxWidth: 300,
                delay     : 300,
                animation : 'scale-subtle',
                allowHTML : true,
                theme     : 'efa',
                touch     : 'hold',
            });
        }
    });

    // Directive: x-tooltip-below
    Alpine.directive('tooltip-bottom', (el, { expression }) => {
        if (typeof el._tippy !== 'undefined' && el._tippy !== null) {
            el._tippy.setContent(expression)
        } else {
            tippy(el, {
                content : expression,
                //maxWidth: 300,
                delay : 300,
                placement : 'bottom',
                animation : 'scale-subtle',
                allowHTML : true,
                theme     : 'efa',
                touch     : 'hold',
            });
        }
    });

    // Directive: x-tooltip-left
    Alpine.directive('tooltip-left', (el, { expression }) => {
        if (typeof el._tippy !== 'undefined' && el._tippy !== null) {
            el._tippy.setContent(expression)
        } else {
            tippy(el, {
                content : expression,
                //maxWidth: 300,
                delay : 300,
                placement : 'left',
                animation : 'scale-subtle',
                allowHTML : true,
                theme     : 'efa',
                touch     : 'hold',
            });
        }
    });

    // Directive: x-tooltip-right
    Alpine.directive('tooltip-right', (el, { expression }) => {
        if (typeof el._tippy !== 'undefined' && el._tippy !== null) {
            el._tippy.setContent(expression)
        } else {
            tippy(el, {
                content : expression,
                //maxWidth: 300,
                delay : 300,
                placement : 'right',
                animation : 'scale-subtle',
                allowHTML : true,
                theme     : 'efa',
                touch     : 'hold',
            });
        }
    });
})

//window.autoSaveForm = require('./auto-save-form').default;
import autoSaveForm from './auto-save-form.js';
window.autoSaveForm = autoSaveForm;


//Alpine.start();
Livewire.start();

// Livewire Sortable
import 'livewire-sortable';
