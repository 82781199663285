// Function for dispatching an event after a modal has been rendered
export function observeParentElementRender(element, parentSelectors, eventName) {
    const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
            // Checks if there are changes in the child elements list
            // in order to make sure that the rendering has been completed
            if (mutation.type === 'childList') {
                // The event with the given name is dispatched as soon as
                // the modal has been rendered
                // const event = new CustomEvent(eventName, {
                //     detail: { message: 'Modal has been rendered' }
                // });
                const event = new Event(eventName);

                element.dispatchEvent(event);

                // This leads to the display of old data from other records.
                // Instead of omitting the disconnection, we could also maybe
                // reinitialize the observer in Alpine's x-init object (see
                // ChatGPT chat https://chatgpt.com/c/672fc8d3-ccb0-8007-a5a0-398e040b2403)
                //observer.disconnect();

                break;
            }
        }
    });

    // Observes the nearest parent modal element
    observer.observe(element.closest(parentSelectors), {
        childList: true,
        subtree: true
    });
}
